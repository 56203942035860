import axios from "axios";
import { timeZone } from "config/timeZone";
import { format, utcToZonedTime, formatInTimeZone } from "date-fns-tz";
import { addMinutes } from "date-fns";
import { ensureValueIsDate } from "lib/shared/ensureValueIsDate";
import { createAdministerLink, createScheduleLink } from "./Prescription";

export const MOBILE_INJECTION_SERVICE_CALENDAR_ID =
  "c_8jdj4mh8qr83h2orr801o9mbds@group.calendar.google.com";

/**
 *
 *
 * @date 2022-12-12
 * @export
 * @param {*} eventData
 * @returns
 */
export async function createGoogleCalendarEvent(eventData) {
  if (!eventData) {
    throw new Error("Must specify eventData");
  }
  const {
    data: {
      data: calendarEvent, // Destructure the calendarEventId from the axios response.
    },
  } = await axios.post("/api/manage-google-calendar-events", {
    calendarId: MOBILE_INJECTION_SERVICE_CALENDAR_ID,
    ...generateGoogleCalendarEventData(eventData),
  });

  return calendarEvent;
}
/**
 *
 *
 * @date 2022-12-12
 * @export
 * @param {string} eventId
 * @param {*} eventData
 * @returns
 */
export async function updateGoogleCalendarEvent(eventId, eventData) {
  if (!eventId || !eventData) {
    throw new Error("Must specify eventId and eventData");
  }
  // update the google calendar event
  return axios.put("/api/manage-google-calendar-events", {
    calendarId: MOBILE_INJECTION_SERVICE_CALENDAR_ID,
    eventId,
    ...generateGoogleCalendarEventData(eventData),
  });
}
/**
 *
 *
 * @date 2022-12-12
 * @export
 * @param {string} eventId
 */
export async function deleteGoogleCalendarEvent(eventId) {
  axios.delete("/api/manage-google-calendar-events", {
    params: { calendarId: MOBILE_INJECTION_SERVICE_CALENDAR_ID, eventId },
  });
}

export function generateGoogleCalendarEventData(eventData) {
  const {
    id: prescriptionId,
    nextInjectionDate,
    orderType,
    frequencyValue,
    frequencyUnits,
    appointmentWithoutSpecificTime,
    appointmentDateTime,
    hasConfirmedAppointment,
    appointmentServiceLocation,
    appointmentComments,
    administrator,
    workflowStatus,
    patient: {
      id: patientId,
      firstName,
      lastName,
      alias,
      gender,
      birthdate,
      phone,
      altPhone,
      defaultServiceLocation,
      defaultDispensingPharmacy,
    },
    medication,
    // Most of the time summary and description will be undefined. In situations whens someone is completing an injection and the calendar event is being marked as completed, these will be present.
    summary,
    description,
  } = eventData;

  const scheduleLink = createScheduleLink(patientId, prescriptionId, true);
  const administerLink = createAdministerLink(patientId, prescriptionId, true);

  // default situation in which there is no appointmentDateTime scheduled, so just creating an all day event based on the when the next injection is due
  let eventStart = {
    date: formatInTimeZone(
      ensureValueIsDate(nextInjectionDate),
      timeZone,
      "yyyy-MM-dd",
    ),
    dateTime: null,
  };
  // there is an appointment scheduled with a specific time
  if (appointmentDateTime && !appointmentWithoutSpecificTime) {
    eventStart = {
      date: null,
      dateTime: utcToZonedTime(
        ensureValueIsDate(appointmentDateTime).toISOString(),
        timeZone,
      ),
    };
  } else if (appointmentDateTime && appointmentWithoutSpecificTime) {
    // this is an appointment scheduled, but without a specific time
    eventStart = {
      date: formatInTimeZone(
        ensureValueIsDate(appointmentDateTime),
        timeZone,
        "yyyy-MM-dd",
      ),
      dateTime: null,
    };
  }

  let eventEnd = {
    date: formatInTimeZone(
      ensureValueIsDate(nextInjectionDate),
      timeZone,
      "yyyy-MM-dd",
    ),
    dateTime: null,
  };

  // there is an appointment scheduled with a specific time
  if (appointmentDateTime && !appointmentWithoutSpecificTime) {
    eventEnd = {
      date: null,
      dateTime: utcToZonedTime(
        addMinutes(ensureValueIsDate(appointmentDateTime), 15).toISOString(),
        timeZone,
      ),
    };
  } else if (appointmentDateTime && appointmentWithoutSpecificTime) {
    // this is an appointment scheduled, but without a specific time
    eventEnd = {
      date: formatInTimeZone(
        ensureValueIsDate(appointmentDateTime),
        timeZone,
        "yyyy-MM-dd",
      ),
      dateTime: null,
    };
  }

  return {
    start: {
      ...eventStart,
      timeZone,
    },
    end: {
      ...eventEnd,
      timeZone,
    },
    location:
      appointmentServiceLocation?.address ?? defaultServiceLocation.address,
    description:
      description ??
      `<p><strong>Patient Name: </strong>${firstName} ${lastName}</p><p><strong>Preferred Name (alias): </strong>${alias}</p><p><strong>Birthdate: </strong>${format(
        utcToZonedTime(ensureValueIsDate(birthdate), timeZone),
        "P",
        {
          timeZone,
        },
      )}</p><p><strong>Gender: </strong>${gender}</p><p><strong>Primary Phone Number: </strong><a href="tel:${phone}">${phone}<a></p><p><strong>Alternate Phone Number: </strong><a href="tel:${altPhone}">${altPhone}<a></p><p><strong>Medication Order: </strong>${
        medication.name
      } ${medication.strength} ${
        orderType === "one-time"
          ? "one time only."
          : `every ${frequencyValue} ${frequencyUnits}.`
      }</p><p><strong>Dispensing Pharmacy: </strong>${defaultDispensingPharmacy}</p><p><strong>Appointment Comments: </strong>${
        appointmentComments ?? ""
      }</p><p><strong>Administer Now: </strong><a href="${administerLink}">${administerLink}</a></p><p><strong>Reschedule: </strong><a href="${scheduleLink}">${scheduleLink}</a></p>`,
    status: "tentative",
    colorId: getEventColorId(
      appointmentServiceLocation?.description ??
        defaultServiceLocation.description,
      administrator,
    ),
    summary:
      summary ??
      `${generateCalendarEventSummaryPrefix(
        administrator,
        workflowStatus,
        hasConfirmedAppointment,
        defaultDispensingPharmacy,
        appointmentServiceLocation?.description ??
          defaultServiceLocation.description,
        medication,
      )}: ${firstName} ${lastName} - ${medication.name} ${medication.strength}`,
    // I was going to use extended properties to help sync with Google Calendar. Not currently using, but more info can be found here: https://developers.google.com/calendar/api/guides/extended-properties#search_properties
    extendedProperties: {
      private: { prescriptionId },
      shared: {
        patientId,
      },
    },
  };
}

export function getEventColorId(serviceLocation, administrator) {
  const ADMINISTRATOR_MAP = {
    GjEYgl8OxKMo2xUWfwJt: "1", // Colby Rueschenberg
    "4vOAovEa8FvVUPoLjPEg": "2", // Rachael Meinders
    x8lg5uHFXfYYqVPvuFkF: "3", // Kathy Pulido
    XvRYU9xVzwxtjfCUdJW2: "4", // Josh Borer
    eAa9QV6GpD5BPW4Xud3B: "6", // Kimberly Vollstedt
    "5e2dxxbyqJDKKreqPow5": "6", // Casey Anderson
    hoUasJTKf2tQ3Bq7SBri: "5", // LuAnn Ihnken
  };
  const SERVICE_MAP = {
    Zion: "7",
    "Paragon Partners": "8",
    "Mobile Injection Service": "9",
    "Manning Pharmacy": "10",
    "Rex Pharmacy (Atlantic)": "11",
  };

  return administrator
    ? ADMINISTRATOR_MAP[administrator.id]
    : SERVICE_MAP[serviceLocation];
}

export function generateCalendarEventSummaryPrefix(
  administrator,
  workflowStatus,
  hasConfirmedAppointment,
  dispensingPharmacy,
  serviceLocation,
  medication,
) {
  const DISPENSING_MAP = {
    "Paragon Partners": "PARAGON",
    "Manning Pharmacy": "MANNING",
    "Rex Pharmacy (Atlantic)": "REX",
  };

  const SERVICE_MAP = {
    "Paragon Partners": "PARAGON",
    "Mobile Injection Service": "MOBILE",
    "Manning Pharmacy": "MANNING",
    "Rex Pharmacy (Atlantic)": "REX",
    Zion: "ZION",
  };

  let eventSummaryPrefix = `${DISPENSING_MAP[dispensingPharmacy]}/${SERVICE_MAP[serviceLocation]}`;

  if (administrator) {
    let administratorPrefix = `${administrator?.firstName?.toUpperCase()}/`;
    eventSummaryPrefix = administratorPrefix + eventSummaryPrefix;
  }

  if (hasConfirmedAppointment) {
    eventSummaryPrefix = "\u00A9 " + eventSummaryPrefix;
  }

  if (medication.isRefrigerated) {
    eventSummaryPrefix = "\u2109 " + eventSummaryPrefix;
  }

  switch (workflowStatus) {
    case "Ready To Be Administered":
      eventSummaryPrefix = "\u2606 " + eventSummaryPrefix;
      break;
    case "Future Adjudicated":
      eventSummaryPrefix = "\u23F3 " + eventSummaryPrefix;
      break;
    case "Own Med":
      eventSummaryPrefix = "\u00AE " + eventSummaryPrefix;
      break;
    default:
      break;
  }

  return eventSummaryPrefix;
}
